import React from 'react';
import styled from 'styled-components';
import {Upload} from 'antd';
import * as Ant from 'antd';
import {LoadingOutlined, PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {useOutlet} from 'reconnect.js';

const UPLOAD_DATA = {url: null, data: null, expected: ''};

function UploadBtn(props) {
  const [imageUrl, setImageUrl] = React.useState('');
  const [uploadData, setUploadData] = React.useState(UPLOAD_DATA);
  const [loading, setLoading] = React.useState('');
  const [actions] = useOutlet('actions');

  const uploadButton = (
    <Ant.Button icon={<UploadOutlined />} style={{width: 60}}>
      {loading && <LoadingOutlined />}
    </Ant.Button>
  );
  return (
    <Upload
      listType="picture"
      className="image-uploader"
      showUploadList={false}
      data={uploadData.data}
      // accept="image/*"
      name="file"
      disabled={loading}
      action={uploadData.url}
      beforeUpload={async (file) => {
        setLoading(true);
        try {
          let resp = await actions.getPresignUrl({
            acl: 'public-read',
            'Content-Type': file.type,
            key: file.name,
          });
          setUploadData({
            url: resp.url,
            data: resp.fields,
            expected: resp.expected,
          });
        } catch (err) {
          setLoading(false);
          Ant.message.warning('無法取得上傳路徑');
        }
      }}
      onChange={async (info) => {
        if (info.file.status === 'uploading') {
          // do progressing
          return;
        }
        if (info.file.status === 'done') {
          setLoading(false);
          setImageUrl(uploadData.expected);
          props.afterActionWithImage(uploadData.expected); // special case in YAS, update order immediately after upload
          return;
        }
      }}>
      {uploadButton}
    </Upload>
  );
}

export default UploadBtn;
