import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import UploadBtn from '../../Components/UploadBtn';
import {useOutlet} from 'reconnect.js';

function BannerDetail(props) {
  const [actions] = useOutlet('actions');
  const {record} = props;
  const defaultValue = {
    name: '',
    image: '',
    order: 1,
    type: props.type,
  };
  const [values, setValues] = React.useState({
    ...defaultValue,
    ...record,
  });
  if (!record) {
    return null;
  }

  const getInputProps = (field) => {
    return {
      value: values[field],
      onChange: (e) => {
        setValues({
          ...values,
          [field]: Number(e.target.value) || e.target.value,
        });
      },
    };
  };

  async function _updateBanner() {
    await actions.updateDocument('banner', {id: record.id}, values);
    await props.onUpdateDismiss();
  }

  async function _createBanner() {
    await actions.createDocument('banner', values);
    await props.onUpdateDismiss();
  }

  function sliceFilename(file) {
    file = decodeURI(file);
    return (file && file.slice(file.lastIndexOf('/') + 1)) || '';
  }

  return (
    <Wrapper>
      <h2>{record.id === undefined ? '新增輪播圖' : '編輯輪播圖'}</h2>
      <section>
        <div className="field">
          <label>圖片名稱</label>
          <Ant.Input {...getInputProps('name')} />
        </div>

        <div className="field" style={{alignItems: 'flex-start'}}>
          <label>上傳檔案</label>
          <UploadBtn
            afterActionWithImage={async (image) => {
              try {
                setValues({...values, image});
                Ant.message.success('上傳圖片成功');
              } catch (ex) {
                console.warn(ex);
              }
            }}
          />
          {(record.image || values.image) && (
            <div style={{marginLeft: 20}}>
              <a
                style={{
                  marginLeft: 10,
                  color: '#3371a2',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                href={values.image || record.image}
                target="_blank"
                rel="noreferrer">
                {sliceFilename(values.image || record.image)}
              </a>
              <img src={values.image || record.image} height="100" />
            </div>
          )}
        </div>
        {props.type === 'home' && (
          <div className="field">
            <label>排序權重</label>
            <Ant.Input {...getInputProps('order')} />
          </div>
        )}
      </section>

      <div className="action-bar">
        <Ant.Button
          type="primary"
          style={{marginRight: 10}}
          onClick={record.id === undefined ? _createBanner : _updateBanner}>
          {record.id === undefined ? `確認新增` : `確認修改`}
        </Ant.Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: white;

  & > h2 {
    padding: 20px;
  }

  & > section {
    padding: 20px;
    border-bottom: 1px solid #ccc;

    & > .field {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      & > label {
        min-width: 80px;
      }

      & > .tag {
        background-color: #ccc;
        border-radius: 4px;
        padding: 5px 12px;
        margin-right: 10px;
      }

      & > .select-wrapper {
        margin-right: 10px;
      }

      & > input {
        flex: 1;
      }

      & > .img-grid {
        flex: 1;
        display: flex;
        flex-wrap: wrap;

        & > figure {
          border-radius: 4px;
          width: 112px;
          height: 112px;
          margin: 0 8px 8px 0;
          background-color: #ccc;
        }
      }
    }
  }

  & > .action-bar {
    padding: 40px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export default BannerDetail;
