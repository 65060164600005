import React from 'react';
import styled from 'styled-components';
import {DeleteFilled} from '@ant-design/icons';
import * as Ant from 'antd';
import BannerDetail from './BannerDetail';
import {useOutlet} from 'reconnect.js';

const Tab = {
  HOME: {value: 'home', display: '首頁'},
  SERVICE: {value: 'service', display: '服務項目'},
  NEWS: {value: 'news', display: '最新消息'},
  ABOUT: {value: 'about', display: '關於我們'},
  TEAM: {value: 'team', display: '醫生團隊'},
  WITNESS: {value: 'witness', display: '美麗見證'},
  CONTACT: {value: 'contact', display: '聯絡我們'},
};

function BannerMgrPage(props) {
  // const app = React.useContext(AppContext.Context);
  const [actions] = useOutlet('actions');
  const [bannerList, setBannerList] = React.useState([]);
  const [selectedDetail, setSelectedDetail] = React.useState(null);
  const [selectedTab, setSelectedTab] = React.useState(Tab.HOME);

  const getTabItemProps = (tab) => {
    return {
      onClick: () => setSelectedTab(tab),
      className: selectedTab === tab ? 'selected' : '',
    };
  };

  async function fetchBannerList() {
    actions.setLoading(true);
    let bannerList = await actions.fetchDocuments('banner');
    bannerList = bannerList.results;
    setBannerList(bannerList);
    actions.setLoading(false);
  }

  async function deleteBanner(id) {
    actions.setLoading(true);
    let bannerList = await actions.deleteDocument('banner', {id});
    actions.setLoading(false);
  }

  React.useEffect(() => {
    fetchBannerList();
  }, []);

  return (
    <Wrapper>
      <h1>Banner圖管理</h1>

      <Ant.Button
        type="default"
        disabled={selectedTab !== Tab.HOME}
        onClick={() => setSelectedDetail({})}>
        ＋新增輪播圖
      </Ant.Button>

      <p>* 請上傳比例為 1440:550 的圖片</p>

      <TabBar style={{marginTop: 20}}>
        {Object.values(Tab).map((tab, idx) => (
          <button key={idx} {...getTabItemProps(tab)}>
            {tab.display}
          </button>
        ))}
      </TabBar>
      {selectedTab === Tab.HOME ? (
        <div className="grid">
          {bannerList
            .filter((banner) => banner.type === selectedTab.value)
            .sort((a, b) => a.order - b.order)
            .map((item, idx) => (
              <div className="item">
                <img src={item.image} height="100" />
                <div style={{fontWeight: 'bold', marginLeft: 20}}>
                  {item.name}
                </div>
                <div
                  style={{
                    color: '#4b4b4b',
                    marginLeft: 20,
                  }}>{`排序權重：${item.order}`}</div>

                <div style={{flex: 1}} />
                <Ant.Button onClick={() => setSelectedDetail(item)}>
                  編輯
                </Ant.Button>
                <div style={{flexBasis: 10}} />
                <Ant.Popconfirm
                  title={`確定刪除?`}
                  onConfirm={async () => {
                    actions.setLoading(true);
                    await deleteBanner(item.id);
                    fetchBannerList();
                    actions.setLoading(false);
                  }}
                  onCancel={() => setSelectedDetail(null)}
                  okText="Yes"
                  cancelText="No">
                  <a href="#">
                    <DeleteFilled />
                  </a>
                </Ant.Popconfirm>
              </div>
            ))}
        </div>
      ) : (
        <div className="grid">
          {bannerList
            .filter((banner) => banner.type === selectedTab.value)
            .map((item) => (
              <div className="item">
                <img src={item.image} height="100" />
                <div style={{fontWeight: 'bold', marginLeft: 20}}>
                  {item.name}
                </div>
                <div style={{flex: 1}} />
                <Ant.Button onClick={() => setSelectedDetail(item)}>
                  編輯
                </Ant.Button>
              </div>
            ))}
        </div>
      )}
      {!!selectedDetail && (
        <Ant.Modal
          title={null}
          footer={null}
          bodyStyle={{padding: 0}}
          visible={true}
          onOk={() => setSelectedDetail(null)}
          onCancel={() => setSelectedDetail(null)}>
          <BannerDetail
            key={new Date().getTime()}
            type={selectedTab.value}
            record={selectedDetail}
            onUpdateDismiss={async () => {
              setSelectedDetail(null);
              actions.setLoading(true);
              fetchBannerList();
              actions.setLoading(false);
            }}
          />
        </Ant.Modal>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: white;
  & > h1 {
    color: #b02b4c;
    font-size: 30px;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
  & > p {
    color: #a7a7a7;
  }

  & > .grid {
    /* display: flex;
    flex-wrap: wrap; */
    & > .item {
      padding: 20px;
      display: flex;
      align-items: center;
      /* justify-content: center; */
      margin: 10px;
      border: 1px solid #eee;
      border-radius: 3px;
    }
  }
`;

const TabBar = styled.div`
  display: flex;
  align-items: center;
  & > button {
    background-color: white;
    padding: 6px 12px;
    color: gray;
    border: none;
    outline: none;
    min-width: 60px;
    border-bottom: 1px solid transparent;
  }

  & > button.selected {
    color: black;
    border-bottom: 1px solid black;
  }
`;

export default BannerMgrPage;
